@import "./../theme-bootstrap";

.BB-styleguide {
  background-color: $color-lighter-gray;
  color: $color-black;
  position: relative;
  &__section {
    margin: 0 10px;
    &__title {
      font-family: $font--avenir-heavy;
      letter-spacing: -0.01em;
      font-weight: normal;
      line-height: 1.17;
      font-size: 41px;
      padding: 1em 0 0;
      text-align: center;
    }
    @include breakpoint($large-up) {
      margin: 0;
    }
  }
  &__subsection {
    background-color: $color-white;
    &__copy {
      padding: 0.75em 0 0.5em;
      text-align: center;
      font-size: 16px;
    }
    &--headers {
      .BB-styleguide__specimen {
        padding-top: 3em;
      }
    }
    &--text {
      .BB-styleguide__specimen {
        padding-top: 3em;
      }
    }
    &--colors {
      .BB-styleguide__specimen {
        padding: 1em 20px;
        &:last-child {
          padding-bottom: 2em;
        }
      }
    }
    &--buttons {
      .BB-styleguide__specimen {
        text-align: center;
      }
    }
    &__title {
      font-family: $font--avenir-heavy;
      letter-spacing: -0.01em;
      font-weight: normal;
      line-height: 1.17;
      font-size: 28px;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      background-color: $color-black;
      color: $color-white;
      padding: 0.5em 0 0.5em;
      text-align: center;
      border-bottom: solid 1px $color-black;
    }
    &--typefaces {
      .BB-styleguide__specimen {
        font-size: 28px;
      }
    }
  }
  &__debug {
    font-size: 14px;
    &__grid {
      width: 100%;
      height: 30px;
      max-width: 1440px;
      margin: 0 auto;
      position: relative;
      top: 35px;
      text-align: center;
      color: $color-banner-gray;
      opacity: 0.6;
      &:after {
        content: "";
        width: 100%;
        height: 1px;
        position: absolute;
        top: 10px;
        #{$ldirection}: 0;
        background: $color-banner-gray;
      }
      span {
        background: $color-lighter-gray;
        padding: 0 10px;
        position: relative;
        z-index: 1;
        &:after {
          content: "$max-width-large: 1440px";
        }
      }
      &--max-width {
        max-width: 1280px;
        span {
          &:after {
            content: "$max-width: 1280px";
          }
        }
      }
    }
    &__breakpoint {
      position: absolute;
      background: $color-black;
      opacity: 0.7;
      color: $color-white;
      top: 0;
      #{$rdirection}: 0;
      padding: 4px 10px;
      &:after {
        content: "mobile (0 - 479px)";
      }
      @include breakpoint($medium-up) {
        &:after {
          content: "$medium-up - tablet (480 - 959px)";
        }
      }
      @include breakpoint($large-up) {
        &:after {
          content: "$large-up - desktop (960px and up)";
        }
      }
    }
  }
  &__inner {
    max-width: 1280px;
    margin: 0 auto;
  }
  &__specimen {
    position: relative;
    border-bottom: solid 1px $color-light-gray;
    min-height: 50px;
    padding: 2em 20px;
    &__title {
      position: absolute;
      background: $color-orange-red;
      color: $color-white;
      min-width: 30px;
      border-radius: 15px;
      top: 10px;
      #{$ldirection}: -4px;
      font-family: $font--sans;
      font-size: 12px;
      padding: 7px 10px;
      z-index: 100;
      @include breakpoint($large-up) {
        #{$ldirection}: -10px;
      }
    }
  }
}
